import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'


const Privacy = () => {

    const meta = {
        title: `About`,
        description: `仮想通貨の特徴や概要、取り扱いのある仮想通貨取引所の情報などを初心者の方にもわかりやすくまとめました。`
    }

    return (
        <Layout>
            <Seo title={meta['title']} description={meta['description']}/>
            <Wrapper>
                <SectionHeader>
                    <SectionHeaderIcon url={`/images/coin_icons/bitcoin_large.png`}/>
                    <h2>プライバシーポリシー</h2>
                </SectionHeader>
                <ContentWrapper>
                    <Content>
                        <p>
                            当サイトではアクセス解析のためにGoogle Analyticsを利用しています。
                        </p>
                        <p>
                            Google Analyticsでは「Cookie」を使用し、個人を特定する情報を含まずにデータを収集します。
                            Google Analyticsの利用により収集されたデータは、Google社のプライバシーポリシーに基づいて管理されます。
                        </p>
                        <p>
                            <a href='https://policies.google.com/privacy?hl=ja'
                               target="_blank" rel="noopener noreferrer">
                                プライバシー ポリシー – ポリシーと規約 – Google</a>
                        </p>
                    </Content>
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export default Privacy


const GrayBackground = styled.div`
    background-color: #fafafa;
`

const Frame = styled.section`
    border-radius: 0.625vw;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 40 / 1280 ) *100vw );
    width: calc(( 1000 / 1280 ) *100vw );
    max-width: 1000px;
    margin: calc(( 75 / 1280 ) *100vw ) auto 0 auto;
    background-color: #fff;
`

const SectionHeader = styled.div`
    position: relative;
    border: solid 0px #ccc;
    text-align: center;
    padding: 150px 0 20px 0;
    margin-top: 20px;

    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0;
        background: transparent;
    }
`

const SectionHeaderIcon = styled.div`
    background: url(${(props) => props.url});
    background-size: contain;

    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 130px;
    height: 130px;

    svg {
        font-size: 5.5rem;
    }
`

const Wrapper = styled(GrayBackground)`
`

const ContentWrapper = styled(Frame)`
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: auto auto;
    column-gap: 20px;
    row-gap: 40px;
    margin: 0 auto 0 auto;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 0 / 1280 ) *100vw );
`

const Content = styled.div`
    grid-row: 1/2;
    grid-column: 2/5;
    margin: 0 auto 0 auto;
    padding: calc(( 40 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw );
    border: solid calc((100vw / 750 ) * 8 ) #e6e6e6;
    border-radius: 8px;
    border-width: 4px;
`